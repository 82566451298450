import { Box, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop } from "../../../../main/components/Backdrop";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ManagePaymentConfirmationStatusLegend } from "../components/ManageDuplicatesLegend";
import { SimplifiedPaymentView } from "../components/SimplifiedPaymentView";
import { IPaymentConfirmation } from "../interfaces/paymentsConfirmationDuplicates";
import { getPaymentConfirmationDuplicates } from "../services/get-payments-confirmation.service";
import { identifyDuplicatesReceivable } from "../utils/identifyDuplicatesReceivable.utils";

export const ManagePaymentConfirmation = () => {
  const query = useParams();
  const toast = useToast();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentsConfirmationDuplicates, setPaymentsConfirmationDuplicates] =
    useState<IPaymentConfirmation[]>([]);

  const paymentConfirmationUuid = query.uuid ?? "";

  const handleFetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getPaymentConfirmationDuplicates(
        paymentConfirmationUuid
      );
      if (response && response.body.length >= 2) {
        const formattedData = identifyDuplicatesReceivable(
          response.body,
          paymentConfirmationUuid
        );
        setPaymentsConfirmationDuplicates(formattedData);
      } else {
        toast({
          title: "Busca inválida",
          description: "Nenhuma duplicidade foi encontrada.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
        navigate("/listpaymentconfirmation");
      }
    } catch (error) {
      toast({
        title: "Erro ao buscar os dados",
        description: "Ocorreu um erro ao buscar os dados. Tente novamente.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      navigate("/listpaymentconfirmation");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    {
      description: "Confirmação de pagamento",
      href: "/listpaymentconfirmation",
    },
    {
      description: "Análise de duplicata",
      href: `/managepaymentconfirmation/${paymentConfirmationUuid}`,
    },
  ];
  if (!paymentConfirmationUuid) {
    toast({
      title: "Erro ao buscar os dados",
      description: "Ocorreu um erro ao buscar os dados. Tente novamente.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    navigate("/listpaymentconfirmation");
  }
  return (
    <Wrapper>
      <Flex flexDir="column">
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Box justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Análise de duplicata
          </HLarge2xl>
          <ManagePaymentConfirmationStatusLegend />
          <Flex
            justifyContent="space-around"
            maxW="full"
            flexWrap="wrap"
            mt={4}
          >
            {paymentsConfirmationDuplicates.length &&
              paymentsConfirmationDuplicates.map(
                (payment: IPaymentConfirmation) => (
                  <SimplifiedPaymentView
                    payment={payment}
                    paymentSelected={paymentConfirmationUuid}
                  />
                )
              )}
          </Flex>
        </Box>
      </Flex>
      {isLoading && <Backdrop />}
    </Wrapper>
  );
};
