/* eslint-disable react/no-unstable-nested-components */
import { Flex, useToast } from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { format_CPF_CNPJ } from "../../../../main/common/utils/format_CPF_CNPJ";
import { Backdrop } from "../../../../main/components/Backdrop";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { PaymentConfirmationPopover } from "../components/Popover";
import { ConfirmationPaymentStatusDescriptionCell } from "../components/StatusCell";
import { StatusLegend } from "../components/StatusLegend";
import { PaymentConfirmationOriginsTranslate } from "../enum/payments-confirmation-origin-translate.enum";
import { PaymentConfirmationOriginsEnum } from "../enum/payments-confirmations-origin.enum";
import { IPaymentConfirmationList } from "../interfaces/paymentConfirmationList.interface";
import { getConfirmationPayments } from "../services/get-payments-confirmation.service";

interface IPaymentConfirmationStateList {
  data: IPaymentConfirmationList[];
  total: number;
}

export const PaymentConfirmationList = () => {
  const toast = useToast();
  const [paymentConfirmations, setPaymentConfirmations] =
    useState<IPaymentConfirmationStateList>({
      data: [],
      total: 0,
    });

  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentConfirmationList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams
  ) => {
    setIsLoading(true);
    try {
      const response = await getConfirmationPayments({ filters, pagination });
      if (response) {
        setPaymentConfirmations({
          data: response.body.data,
          total: response.body.lastPage,
        });
      }
    } catch (error) {
      toast({
        title: "Erro ao buscar os dados",
        description: "Ocorreu um erro ao buscar os dados. Tente novamente.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    columnFiltersParams,
    paginationParams,
    columnFiltersState,
    paginationState,
  } = useTableServer({
    pageCount: paymentConfirmations.total,
  });

  const columns = useMemo<ColumnDef<IPaymentConfirmationList, any>[]>(
    () => [
      {
        header: "Status",
        accessorKey: "status",
        filterFn: "selectPaymentConfirmationStatus",
        enableSorting: false,
        cell: (info) => (
          <ConfirmationPaymentStatusDescriptionCell info={info} />
        ),
      },
      {
        header: "ID",
        accessorKey: "id",
        filterFn: "fuzzy",
      },
      {
        header: "Cliente",
        accessorKey: "client",
        filterFn: "fuzzy",
        minSize: 350,
      },
      {
        header: "Documento",
        accessorKey: "document",
        filterFn: "document",
        cell: (info) => format_CPF_CNPJ(info.getValue()) ?? "",
      },
      {
        header: "Origem",
        accessorKey: "origin",
        filterFn: "fuzzy",
        cell: (info) =>
          PaymentConfirmationOriginsTranslate[
            info.getValue() as PaymentConfirmationOriginsEnum
          ],
      },
      {
        header: "Data de pagamento",
        accessorKey: "paidAt",
        filterFn: "fuzzy",
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "Data de criação",
        accessorKey: "createdAt",
        filterFn: "fuzzy",
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "Data de reembolso",
        accessorKey: "refundedAt",
        filterFn: "fuzzy",
        cell: (info) => {
          return getDateFormatted(info.getValue());
        },
      },
      {
        header: "Valor",
        accessorKey: "amount",
        filterFn: "fuzzy",
        cell: (info) => {
          return Number(info.getValue() / 100 || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        header: "Método de pagamento",
        accessorKey: "paymentMethod",
        filterFn: "fuzzy",
      },
      {
        header: "Ações",
        id: "actions",
        cell: (info) => <PaymentConfirmationPopover info={info} />,
      },
    ],
    []
  );

  useEffect(() => {
    handlePaymentConfirmationList(paginationParams, columnFiltersParams);
  }, [columnFiltersParams, paginationParams]);

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    {
      description: "Confirmação de pagamento",
      href: "/listpaymentconfirmation",
    },
  ];

  return (
    <Wrapper>
      <Flex flexDir="column">
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Confirmação de pagamento
          </HLarge2xl>
        </Flex>
        <TableServer
          refresh={handlePaymentConfirmationList}
          data={paymentConfirmations.data}
          defaultColumns={columns}
          isLoading={isLoading}
          paginationState={paginationState}
          columnFiltersState={columnFiltersState}
        />
        <StatusLegend />
      </Flex>
      {isLoading && <Backdrop />}
    </Wrapper>
  );
};
