import { IFieldDefault } from "./fieldDefault";

export enum enumTypeOfPaymentType {
  "AUTOMATIC_DEBIT" = "Débito automático",
  "BOLETO" = "Boleto",
  "THIRD_PARTY_DEBIT" = "Débito de terceiros",
  "PRE_PAID" = "Pré-pago",
  "PIX" = "PIX",
}

interface IPaymentTypeFields {
  name: keyof typeof enumTypeOfPaymentType;
}

type IPaymentType = IFieldDefault & IPaymentTypeFields;

export type { IPaymentType, IPaymentTypeFields };
