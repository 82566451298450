/* eslint-disable react/no-unstable-nested-components */

/* eslint-disable no-promise-executor-return */
import { CopyIcon } from "@chakra-ui/icons";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { format_CPF_CNPJ } from "../../../../main/common/utils/format_CPF_CNPJ";
import { Backdrop } from "../../../../main/components/Backdrop";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl, HSmallmd } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { PopoverPaymentCharges } from "../components/PopoverPaymentsChargesHistory/PopoverPaymentsChargesHistory";
import {
  PaymentChargeOriginEnum,
  PaymentOriginTranslate,
} from "../enums/paymentChargeOrigin.enum";
import { paymentChargeStatus } from "../enums/PaymentChargeStatusEnum";
import { paymentsChargesService } from "../services/PaymentsChargesService";
import { IPaymentsChargesFilters } from "../types/payments-charge-filters.interface";
import { IPaymentsChargesListResponse } from "../types/paymentsChargesListResponse.interface";

export const PaymentChargeHistoricList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentCharges, setPaymentCharges] = useState<{
    data: IPaymentsChargesListResponse[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const toast = useToast();

  const handleSearch = async (
    filters?: IPaymentsChargesFilters,
    pagination?: PaginationState,
    columnFilters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);

    try {
      const response = await paymentsChargesService.getAll({
        filters,
        pagination,
        columnFilters,
        sorting,
      });

      if (response.type === "success" && response.body) {
        setPaymentCharges({
          data: response.body.data,
          total: response.body.lastPage,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: paymentCharges.total,
  });

  const columns = useMemo<ColumnDef<IPaymentsChargesListResponse, any>[]>(
    () => [
      {
        header: "Status",
        accessorFn: (row: any) => row.status,
        cell: (info) => (
          <BadgeTooltip
            label={
              paymentChargeStatus[
                info.getValue() as any as keyof typeof paymentChargeStatus
              ]?.description ?? ""
            }
            colorScheme={
              paymentChargeStatus[
                info.getValue() as keyof typeof paymentChargeStatus
              ]?.color ?? "gray"
            }
          />
        ),
        enableSorting: false,
        minSize: 50,
        enableColumnFilter: false,
      },
      {
        enableSorting: false,
        header: "Documento",
        accessorKey: "clientDocument",
        cell: (info) => format_CPF_CNPJ(info.getValue()) ?? "",
      },
      {
        enableSorting: false,
        header: "Cliente",
        accessorKey: "clientName",
      },
      {
        header: "Valor",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Number(info.getValue() || 0).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Link",
        accessorKey: "transactionCode",
        cell: (info) => (
          <Box>
            <Flex
              py="8px"
              as="button"
              alignItems="center"
              transition="all 0.2s ease-in-out"
              onClick={() =>
                navigator.clipboard.writeText(info.getValue()).then(
                  () => {
                    toast({
                      title: "Copiado!",
                      status: "success",
                    });
                  },
                  () => {
                    toast({
                      title: "Houve um erro ao copiar o código",
                      status: "error",
                    });
                  }
                )
              }
            >
              <CopyIcon />
              <HSmallmd ml="8.46px">{info.getValue()}</HSmallmd>
            </Flex>
          </Box>
        ),
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Código",
        accessorKey: "paymentCode",
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Origem",
        accessorKey: "origin",
        cell: (info) =>
          PaymentOriginTranslate[info.getValue() as PaymentChargeOriginEnum],
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Qntd. Parcelas",
        accessorKey: "installments",
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Beneficiário",
        accessorKey: "accountName",
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Chave PIX",
        accessorKey: "pixKey",
      },
      {
        header: "Criado em",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Intl.DateTimeFormat("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(info.getValue()));
        },
      },
      {
        header: "Pago em",
        accessorKey: "paidAt",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          return Intl.DateTimeFormat("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(info.getValue()));
        },
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Expira em",
        accessorKey: "expireIn",
        cell: (info) => {
          return Intl.DateTimeFormat("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(info.getValue()));
        },
      },
      {
        enableColumnFilter: false,
        enableSorting: false,
        header: "Estornado em",
        accessorKey: "refundedAt",
        cell: (info) => {
          return Intl.DateTimeFormat("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(info.getValue()));
        },
      },
      {
        id: "actions",
        cell: (info) => (
          <PopoverPaymentCharges
            info={info}
            onCancelPixSuccess={handleSearch}
          />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const filters = {
      ...columnFiltersParams,
    };
    handleSearch(undefined, paginationParams, filters, columnSortingParams);
  }, [columnFiltersParams, columnSortingParams, paginationParams]);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Pagamentos ",
      href: "/pagamentos",
    },
  ];

  return (
    <Wrapper>
      {isLoading && <Backdrop />}
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600">Pagamentos</HLarge2xl>
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" />
      <TableServer
        refresh={() => handleSearch()}
        data={paymentCharges.data}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={paginationState}
        sortingState={sortingState}
      />
    </Wrapper>
  );
};
