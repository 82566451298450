import { PaginationState } from "@tanstack/react-table";
import { TableFilteredParams } from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";

type PaymentConfirmationListParams = {
  pagination?: PaginationState;
  filters?: TableFilteredParams;
};

export const getConfirmationPayments = async ({
  filters,
  pagination,
}: PaymentConfirmationListParams) => {
  const page = pagination?.pageIndex;
  const response = await recordApi.get("payments-confirmation", {
    params: {
      page: page === undefined ? 1 : page + 1,
      take: pagination?.pageSize ?? 10,
      ...filters,
    },
  });
  return response.data;
};

export const getPaymentConfirmationDuplicates = async (uuid: string) => {
  const response = await recordApi.get(
    `payments-confirmation/${uuid}/duplicates`
  );
  return response.data;
};
