"use client";

import {
  Badge,
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IconPix } from "../../../../main/common/assets";
import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import { PaymentChargeStatusEnum } from "../../PaymentsChargesHistory/enums/PaymentChargeStatusEnum";
import { PaymentConfirmationOriginsTranslate } from "../enum/payments-confirmation-origin-translate.enum";
import { IPaymentConfirmation } from "../interfaces/paymentsConfirmationDuplicates";
import { ManagerCarDuplicatesPopover } from "./ManagerCarDuplicatesPopover";
import { ManagerPaymentDuplicatesPopover } from "./ManagerPaymentDuplicatesPopover";

export const SimplifiedPaymentView = ({
  payment,
  paymentSelected,
}: {
  payment: IPaymentConfirmation;
  paymentSelected: string;
}) => {
  const highlightBg = useColorModeValue("yellow.50", "yellow.900");
  const highlightBgForDuplicates = useColorModeValue("red.50", "blue.900");
  const highlightBorder = useColorModeValue("yellow.400", "yellow.600");
  const highlightBorderForDuplicates = useColorModeValue("red.400", "red.600");

  return (
    <Box
      mb={6}
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      bg="white"
      boxShadow="md"
      w={580}
    >
      <Box
        p={4}
        bg={payment.uuid === paymentSelected ? "blue.600" : "blue.400"}
        color="white"
        borderRadius="md"
        mb={4}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">
            {payment.uuid === paymentSelected
              ? "Pagamento Selecionado"
              : "Pagamento Relacionado"}
          </Heading>
          <Stack direction="row" spacing={2} align="center">
            <Badge
              colorScheme="blue"
              fontSize="md"
              px={2}
              py={1}
              borderRadius="md"
            >
              {payment.id}
            </Badge>
            <ManagerPaymentDuplicatesPopover payment={payment} />
          </Stack>
        </Flex>
      </Box>

      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        mb={4}
      >
        <Text>
          <strong>Pago via:</strong> <br />
          {PaymentConfirmationOriginsTranslate[payment.origin]}
        </Text>
        <Text>
          <strong>Data:</strong> <br />
          {getDateFormatted(payment.paidAt)}
        </Text>
        <Text>
          <strong>Valor Total:</strong>
          <br /> R$ {(payment.amount / 100).toFixed(2)}
        </Text>
      </Flex>

      <Box borderTopWidth="1px" my={4} />

      <Heading size="sm" mb={3}>
        Cobranças deste pagamento:
      </Heading>
      <Stack spacing={2} height="350px" overflowY="auto">
        {payment.tariffCars.map((car) => (
          <Flex
            key={car.id}
            p={3}
            borderRadius="md"
            bg={car.isDuplicate ? highlightBgForDuplicates : highlightBg}
            borderLeftWidth="4px"
            borderLeftColor={
              car.isDuplicate ? highlightBorderForDuplicates : highlightBorder
            }
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={2} align="center">
              <Badge colorScheme={car.isDuplicate ? "red" : "blue"}>
                {car.id}
              </Badge>
              <Text maxW="220px" isTruncated title={car.service}>
                {car.service}
              </Text>
            </Stack>
            <Stack direction="row" spacing={2} align="center">
              {car.paymentChargeStatus === PaymentChargeStatusEnum.PAID && (
                <IconPix style={{ width: "24px", height: "auto" }} />
              )}
              <Text fontWeight="bold">R$ {car.amount.toFixed(2)}</Text>
              <ManagerCarDuplicatesPopover car={car} />
            </Stack>
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};
