import { CellContext } from "@tanstack/react-table";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import { paymentConfirmationStatusColor } from "../enum/statusColor.enum";
import { IPaymentConfirmationList } from "../interfaces/paymentConfirmationList.interface";

export const ConfirmationPaymentStatusDescriptionCell = ({
  info,
}: {
  info: CellContext<IPaymentConfirmationList, any>;
}) => {
  const { original } = info.row;

  const { status } = original;

  return (
    <BadgeTooltip
      label={status}
      colorScheme={paymentConfirmationStatusColor[status]}
    />
  );
};
