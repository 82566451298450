import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IProvider,
  IProviderData,
  IProviderList,
  IProviderOption,
} from "../../../../types/main/providers";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/providers";

export const getProvidersOptions = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string | null;
}) => {
  try {
    const response: AxiosResponse<IResponse<Array<IProviderOption>>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}/options?return_even_if_deleted=${return_even_if_deleted}`
          : `${MODULE_BASE_URL}/options`
      );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getAllServiceProviders = async () => {
  try {
    const response: AxiosResponse<IResponse<Array<IProviderList>>> =
      await recordApi.get(MODULE_BASE_URL);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getAllServiceProvidersPixConfigurations = async (): Promise<
  IResponse<Array<any>>
> => {
  try {
    const response = await recordApi.get(
      `${MODULE_BASE_URL}/pix-configurations`
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getServiceProviderById = async (uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<IProvider>> = await recordApi.get(
      `${MODULE_BASE_URL}/search/${uuid}`
    );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const deleteServiceProvider = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const newServiceProvider = async (data: IProviderData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, data);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const updateServiceProvider = async (
  data: IProviderData,
  uuid: string
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, data);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
