import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DEFAULT_REGEX_ESPECIAL_CHARACTERS } from "../../../../../../main/common/constants/defaultValidations";
import { Backdrop } from "../../../../../../main/components/Backdrop";
import { InputSelect } from "../../../../../../main/components/InputSelect";
import { SimpleMultiSelect } from "../../../../../../main/components/MultiSelect/SimpleMultiSelect";
import { IPaymentLayout } from "../../../../../../types/main/paymentLayout";
import {
  IPaymentMethods,
  IPaymentMethodsData,
} from "../../../../../../types/main/paymentMethods";
import { IPaymentTransmissionChannel } from "../../../../../../types/main/paymentTransmissionChannel";
import { IPaymentType } from "../../../../../../types/main/paymentType";
import { ITemplates } from "../../../../../../types/main/templates";
import { PaymentMethodTypesEnum } from "../../../enums/paymentMethodTypes.enum";
import { pixFormSchema } from "../../../validations/pixFormSchema";
import { ButtonSubmit } from "../ButtonSubmit";

interface IProps {
  isReadOnly?: boolean;
  paymentMethodUUID?: string;
  onSubmitToCreatePaymentMethod: (data: IPaymentMethodsData) => Promise<void>;
  onSubmitToUpdatePaymentMethod: (data: IPaymentMethodsData) => Promise<void>;
  allTypes: Array<IPaymentType>;
  existingPaymentMethod?: IPaymentMethods;
  allLayout?: Array<IPaymentLayout>;
  allTemplates?: Array<ITemplates>;
  allTransmissionChannel?: Array<IPaymentTransmissionChannel>;
  providerBankConfigurations?: Array<any>;
}

interface IFormData
  extends Omit<
    IPaymentMethodsData,
    "paymentTransmissionChannel" | "instructions"
  > {
  paymentTransmissionChannel: string[][];
  instructions?: {
    text: string;
  }[];
}

export const PixForm = ({
  paymentMethodUUID,
  isReadOnly,
  onSubmitToCreatePaymentMethod,
  onSubmitToUpdatePaymentMethod,
  allTypes,
  existingPaymentMethod,
  allLayout,
  allTemplates,
  allTransmissionChannel,
  providerBankConfigurations = [],
}: IProps) => {
  const methods = useForm<IFormData>({
    resolver: yupResolver(pixFormSchema),
  });

  const [emailSelected, setEmailSelected] = useState<boolean>(false);

  const watchPaymentTransmissionChannel = methods.watch(
    "paymentTransmissionChannel"
  );

  useEffect(() => {
    if (
      existingPaymentMethod &&
      allLayout &&
      allTemplates &&
      allTransmissionChannel
    ) {
      methods.setValue(
        "paymentLayoutUuid",
        existingPaymentMethod.paymentLayoutUuid
      );
      methods.setValue(
        "providerBankConfigurationUuid",
        existingPaymentMethod.providerBankConfigurationUuid
      );
      methods.setValue("description", existingPaymentMethod.description);
      methods.setValue("paymentTransmissionChannel", [
        [
          ...existingPaymentMethod.paymentTransmissionChannel.map(
            ({ uuid }) => uuid
          ),
        ],
        [
          ...existingPaymentMethod.paymentTransmissionChannel.map(
            ({ name }) => name
          ),
        ],
      ]);
      methods.setValue("templateUuid", existingPaymentMethod.templateUuid);
    }
    if (allTypes.length > 0) {
      methods.setValue(
        "paymentTypeUuid",
        allTypes.filter(({ name }) => name === "PIX")[0].uuid
      );
    }
  }, [
    allLayout,
    allTemplates,
    allTransmissionChannel,
    existingPaymentMethod,
    methods,
    allTypes,
  ]);

  useEffect(() => {
    const paymentTransmissionChannelContainEmail =
      watchPaymentTransmissionChannel &&
      watchPaymentTransmissionChannel[1]?.filter((item) => item === "E-mail")
        .length === 1;
    setEmailSelected(paymentTransmissionChannelContainEmail);
    if (
      !paymentTransmissionChannelContainEmail &&
      watchPaymentTransmissionChannel?.length > 0
    ) {
      methods.setValue("templateUuid", "");
    }
  }, [methods, watchPaymentTransmissionChannel, paymentMethodUUID]);

  if (
    (!existingPaymentMethod && paymentMethodUUID) ||
    !allLayout ||
    !allTemplates ||
    !allTransmissionChannel
  )
    return <Backdrop />;
  return (
    <FormProvider {...methods}>
      <Flex
        mt="32px"
        as="form"
        onSubmit={methods.handleSubmit(async (data) => {
          const [firstArray] = data.paymentTransmissionChannel;
          const { instructions } = data;
          if (paymentMethodUUID) {
            await onSubmitToUpdatePaymentMethod({
              ...data,
              paymentTransmissionChannel: firstArray,
              instructions: [],
            });
            return;
          }
          await onSubmitToCreatePaymentMethod({
            ...data,
            paymentTransmissionChannel: firstArray,
            instructions: [],
          });
        })}
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Grid
          h="300px"
          templateRows="(4, 1fr)"
          templateColumns="repeat(12, 1fr)"
          gap={8}
        >
          <GridItem colSpan={4}>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentLayoutUuid}
            >
              <FormLabel htmlFor="paymentLayoutUuid">Layout</FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="paymentLayoutUuid"
                placeholder="Layout"
                isClearable
                option={
                  allLayout
                    ?.filter(
                      ({ paymentType }) =>
                        paymentType.name === PaymentMethodTypesEnum.PIX ||
                        paymentType.name === PaymentMethodTypesEnum.BOLETO
                    )
                    .map(({ uuid: value, name: label }) => {
                      return { label, value };
                    }) ?? []
                }
              />

              <FormErrorMessage>
                {methods.formState.errors.paymentLayoutUuid &&
                  methods.formState.errors.paymentLayoutUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.description}>
              <FormLabel htmlFor="description">
                Nome do método de pagamento
              </FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "not-allowed",
                  color: "inherit",
                  opacity: "inherit",
                }}
                id="description"
                placeholder="Texto"
                {...methods.register("description", {
                  onChange: (event: any) => {
                    methods.setValue(
                      "description",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    methods.trigger();
                  },
                })}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.description &&
                  methods.formState.errors.description.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentTransmissionChannel}
            >
              <FormLabel htmlFor="paymentTransmissionChannel">
                Canal de transmissão
              </FormLabel>
              <SimpleMultiSelect
                buttonLabel="Selecionar"
                defaultOptions={
                  allTransmissionChannel
                    ? allTransmissionChannel
                        .filter(
                          ({ paymentType }) => paymentType.name === "BOLETO"
                        )
                        .map(({ uuid, name }) => {
                          return {
                            label: name,
                            uuid,
                            checked: existingPaymentMethod
                              ? existingPaymentMethod.paymentTransmissionChannel.some(
                                  ({ uuid: search }) => search === uuid
                                )
                              : false,
                          };
                        })
                    : []
                }
                fieldName="paymentTransmissionChannel"
                setPlaceHolderWith="label"
                isDisabled={isReadOnly}
              />
              <FormErrorMessage>
                {methods.formState.errors.paymentTransmissionChannel &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3} hidden={!emailSelected}>
            <FormControl isInvalid={!!methods.formState.errors.templateUuid}>
              <FormLabel htmlFor="templateUuid">Template de e-mail</FormLabel>
              <InputSelect
                disabled={isReadOnly || !emailSelected}
                name="templateUuid"
                placeholder="Template"
                isClearable
                option={
                  allTemplates.map(({ uuid, name, deletedAt }) => {
                    return {
                      label: deletedAt ? `(inativo) ${name}` : name,
                      value: uuid,
                      deletedAt,
                    };
                  }) ?? []
                }
              />
              <FormErrorMessage>
                {methods.formState.errors.templateUuid &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={5}>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentLayoutUuid}
            >
              <FormLabel htmlFor="paymentLayoutUuid">
                Configuração Bancária
              </FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="providerBankConfigurationUuid"
                placeholder="Configuração PIX"
                isClearable
                option={
                  providerBankConfigurations.map(
                    ({ uuid: value, description, provider, pixKey }) => {
                      return {
                        label: `${provider.name} -${description} - Chave PIX: ${pixKey}`,
                        value,
                      };
                    }
                  ) ?? []
                }
              />

              <FormErrorMessage>
                {methods.formState.errors.paymentLayoutUuid &&
                  methods.formState.errors.paymentLayoutUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={12}>
            <ButtonSubmit
              paymentMethodUUID={paymentMethodUUID}
              isDisabled={isReadOnly}
              isSubmitting={methods.formState.isSubmitting}
            />
          </GridItem>
        </Grid>
      </Flex>
    </FormProvider>
  );
};
