import { Badge, Flex, HStack } from "@chakra-ui/react";
import { PaymentConfirmationStatus } from "../enum/payments-confirmation-status.enum";

export const StatusLegend = () => {
  return (
    <HStack
      spacing="48px"
      fontSize="xs"
      p="10px"
      borderRadius="5px"
      width="fit-content"
    >
      <Flex gap="5px">
        <Badge
          backgroundColor="green.400"
          h="16px"
          w="16px"
          borderRadius="50%"
        />
        {PaymentConfirmationStatus.CONFIRMED}
      </Flex>
      <Flex gap="5px">
        <Badge backgroundColor="red.400" h="16px" w="16px" borderRadius="50%" />
        {PaymentConfirmationStatus.DUPLICATE}
      </Flex>
      <Flex gap="5px">
        <Badge
          backgroundColor="gray.400"
          h="16px"
          w="16px"
          borderRadius="50%"
        />
        {PaymentConfirmationStatus.REFUNDED}
      </Flex>
    </HStack>
  );
};
