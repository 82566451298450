import { ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IconDelete } from "../../../../main/common/assets";
import { HSmallmd } from "../../../../main/components/Tipography";
import { IPaymentConfirmationList } from "../interfaces/paymentConfirmationList.interface";
import { deletePaymentConfirmationService } from "../services/delete-payment-confirmation.service";

interface IPaymentConfirmationPopoverProps {
  info: CellContext<IPaymentConfirmationList, any>;
}
export const PaymentConfirmationPopover = ({
  info,
}: IPaymentConfirmationPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const paymentConfirmation = info.row.original;

  const handleDelete = async () => {
    setIsLoading(true);
    onClose();
    try {
      await deletePaymentConfirmationService(paymentConfirmation.uuid);
      toast({
        title: "Confirmação de pagamento excluída",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao excluir a confirmação de pagamento",
        description: "Ocorreu um erro ao excluir a confirmação de pagamento.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="auto" paddingRight={4}>
              <PopoverBody>
                <Flex flexDir="column">
                  <Flex
                    mt="2"
                    flexDir="row"
                    justifyContent="flex-start"
                    gap="2"
                    alignItems="center"
                    color="#718096"
                    as={Button}
                    variant="unstyled"
                    padding="0"
                    _hover={{ color: "red.500" }}
                    onClick={handleDelete}
                  >
                    <IconDelete color="red.200" />
                    <HSmallmd ml="8.46px" color="red.400">
                      Excluir confirmação
                    </HSmallmd>
                  </Flex>
                  {paymentConfirmation.hasDuplicates && (
                    <Flex
                      mt="2"
                      flexDir="row"
                      justifyContent="flex-start"
                      gap="2"
                      alignItems="center"
                      color="#718096"
                      as={Link}
                      _hover={{ color: "#444" }}
                      to={`/managepaymentconfirmation/${paymentConfirmation.uuid}`}
                      padding="0"
                    >
                      <ViewIcon color="gray.500" />
                      <HSmallmd ml="8.46px">Visualizar detalhes</HSmallmd>
                    </Flex>
                  )}
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
