import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { IPaymentTransmissionChannel } from "../../../../types/main/paymentTransmissionChannel";
import { getAllServiceProvidersPixConfigurations } from "../../ServiceProvider/services/ServiceProviderService";

export const useGetAllActiveProvidersPixConfigurations = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [providersPixConfigurations, setProvidersPixConfigurations] =
    useState<Array<IPaymentTransmissionChannel>>();

  const findData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getAllServiceProvidersPixConfigurations();
      if (response.type === "success" && response.body) {
        setProvidersPixConfigurations(response.body);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    findData();
  }, [findData]);

  return { providersPixConfigurations, isLoading };
};
