import { Badge, Flex, HStack } from "@chakra-ui/react";
import { IconPix } from "../../../../main/common/assets";

export const ManagePaymentConfirmationStatusLegend = () => {
  return (
    <HStack
      spacing="48px"
      fontSize="xs"
      p="10px"
      borderRadius="5px"
      width="fit-content"
    >
      <Flex gap="5px">
        <Badge
          backgroundColor="yellow.400"
          h="16px"
          w="16px"
          borderRadius="50%"
        />
        CAR sem pagamento duplicado
      </Flex>
      <Flex gap="5px">
        <Badge backgroundColor="red.400" h="16px" w="16px" borderRadius="50%" />
        CAR com pagamento duplicado
      </Flex>
      <Flex gap="5px" align="center">
        <IconPix style={{ width: "24px", height: "auto" }} />
        CAR pago via PIX. Possibilidade de estorno parcial.
      </Flex>
    </HStack>
  );
};
