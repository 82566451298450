import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import { GET_PAYMENT_CONFIRMATION_PERMISSIONS_LEVEL } from "./constants/permission";
import { ManagePaymentConfirmation } from "./pages/ManagePaymentConfirmation";
import { PaymentConfirmationList } from "./pages/PaymentConfirmationList";

export const PaymentConfirmationModule = () => (
  <Routes>
    <Route
      element={
        <PrivateRoute permission={GET_PAYMENT_CONFIRMATION_PERMISSIONS_LEVEL} />
      }
    >
      <Route
        path="/listpaymentconfirmation"
        element={<PaymentConfirmationList />}
      />
      <Route
        path="/managepaymentconfirmation/:uuid"
        element={<ManagePaymentConfirmation />}
      />
    </Route>
  </Routes>
);
