import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { RiRefund2Line } from "react-icons/ri";
import { HSmallmd } from "../../../../main/components/Tipography";
import { PaymentChargeStatusEnum } from "../../PaymentsChargesHistory/enums/PaymentChargeStatusEnum";
import { paymentsChargesService } from "../../PaymentsChargesHistory/services/PaymentsChargesService";
import { IPaymentConfirmation } from "../interfaces/paymentsConfirmationDuplicates";

interface IManagerPaymentDuplicatesPopoverProps {
  payment: IPaymentConfirmation;
}
export const ManagerPaymentDuplicatesPopover = ({
  payment,
}: IManagerPaymentDuplicatesPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const isAllCarDuplicate = payment.tariffCars.every((car) => car.isDuplicate);
  const isAllCarPaidByPix = payment.tariffCars.every(
    (car) => car.paymentChargeStatus === PaymentChargeStatusEnum.PAID
  );

  const handleRefund = async () => {
    setIsLoading(true);
    onClose();
    try {
      await paymentsChargesService.refund({
        paymentChargeUuid: payment.tariffCars[0].paymentChargeUuid ?? "",
        amount: payment.amount,
      });
      toast({
        title: "Estorno solicitado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Erro ao solicitar estorno",
        description: error.message ?? "Ocorreu um erro ao solicitar estorno.",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover
      placement="right"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="white"
              variant="outline"
              border="0"
              color="white.700"
              _hover={{ bg: "blue.700" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="white.200"
                  color="white.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="auto" paddingRight={4}>
              <PopoverBody>
                <Flex flexDir="column">
                  <Flex
                    mt="2"
                    flexDir="row"
                    justifyContent="flex-start"
                    gap="2"
                    alignItems="center"
                    color="#718096"
                    as={Button}
                    variant="unstyled"
                    padding="0"
                    _hover={{ color: "red.500" }}
                    disabled={
                      !isAllCarDuplicate || !isAllCarPaidByPix || isLoading
                    }
                    onClick={handleRefund}
                  >
                    <RiRefund2Line color="red.200" />
                    <HSmallmd ml="8.46px" color="red.400">
                      Estornar valor do Pagamento
                    </HSmallmd>
                  </Flex>

                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
