export enum PaymentChargeOriginEnum {
  LIBERTAS = "LIBERTAS",
  PORTAL_COOPERCOMPANY = "PORTAL_COOPERCOMPANY",
  ISBANK = "ISBANK",
  MANUALLY = "MANUALLY",
  SELF_SALES = "SELF_SALES",
  STARTAG = "STARTAG",
}

export const PaymentOriginTranslate = {
  [PaymentChargeOriginEnum.LIBERTAS]: "Libertas",
  [PaymentChargeOriginEnum.PORTAL_COOPERCOMPANY]: "Portal CooperCompany",
  [PaymentChargeOriginEnum.ISBANK]: "Isbank",
  [PaymentChargeOriginEnum.MANUALLY]: "Manual",
  [PaymentChargeOriginEnum.SELF_SALES]: "Autosserviço",
  [PaymentChargeOriginEnum.STARTAG]: "Startag",
};
