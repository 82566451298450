import { IPaymentConfirmation } from "../interfaces/paymentsConfirmationDuplicates";

export const identifyDuplicatesReceivable = (
  paymentsConfirmationDuplicates: IPaymentConfirmation[],
  paymentSelectedUuid: string
) => {
  const payments = paymentsConfirmationDuplicates.map((payment) => {
    const tariffCarsWithDuplicateFlag = payment.tariffCars.map((tariffCar) => {
      const isDuplicate = paymentsConfirmationDuplicates.some(
        (otherPayment) =>
          otherPayment !== payment &&
          otherPayment.tariffCars.some(
            (otherTariffCar) => otherTariffCar.id === tariffCar.id
          )
      );
      return {
        ...tariffCar,
        isDuplicate,
      };
    });

    const tariffCarSortedByIdLevel = tariffCarsWithDuplicateFlag.sort(
      (a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      }
    );
    const tariffCarSortedByDuplicateLevel = tariffCarSortedByIdLevel.sort(
      (a, b) => {
        if (a.isDuplicate === b.isDuplicate) {
          return 0;
        }
        return a.isDuplicate ? -1 : 1;
      }
    );

    return {
      ...payment,
      tariffCars: tariffCarSortedByDuplicateLevel,
    };
  });

  const sortedPayments = payments.sort((a, b) => {
    if (a.uuid === paymentSelectedUuid) return -1;
    if (b.uuid === paymentSelectedUuid) return 1;
    return 0;
  });
  return sortedPayments;
};
